import { useState } from 'react';
import Link from 'next/link';
import styles from 'styles/Navbar.module.scss';
import SvgLogoName from 'assets/brand/logo-full-name.svg';
import SvgHamburgerMenu from 'assets/svg/hamburger-icon.svg';
import SvgCloseX from 'assets/svg/close-x.svg';

export default function Navbar() {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(prev => !prev);
	}

	const handleCloseMenu = () => {
		setOpen(false);
	}
	
	return (
		<>
			<header className={ styles.header }>
				<ul>
					<li><SvgLogoName /></li>
					<li>
						<Link href='tel:+1-604-523-2220' className={ styles.tel }>1-604-523-2220</Link>
						<Link href='mailto:neonlight@inbox.com' className={ styles.navCta }>Request A Quote</Link>
					</li>
				</ul>
			</header>
			<nav className={ styles.navbar }>
				<ul data-menu-status={ open ? 'open' : 'closed' }>
					<li><Link href='/' onClick={ handleCloseMenu }>Home</Link></li>
					<li><Link href='/services' onClick={ handleCloseMenu }>Services</Link></li>
					<li><Link href='/about' onClick={ handleCloseMenu }>About Us</Link></li>
					<li><Link href='/gallery' onClick={ handleCloseMenu }>Gallery</Link></li>
					<li><Link href='/contact' onClick={ handleCloseMenu }>Contact Us</Link></li>
				</ul>
				<button onClick={ handleOpen } id='nav-menu' role='button' title='Menu Toggle'>
					{ open ? <SvgCloseX /> : <SvgHamburgerMenu /> }
				</button>
			</nav>
		</>
	)
}
