import styles from 'styles/Footer.module.scss';
import Link from 'next/link';

export default function Footer() {

	return (
		<footer className={ styles.footer }>
			<section className={ styles.container }>
				<div>
					<p>Company</p>
					<hr />
					<ul>
						<li>VancouverSignExpert.com</li>
						<li><Link href='tel:+1-604-523-2220'>1-604-523-2220</Link></li>
						<li><Link href='mailto:neonlight@inbox.com'>neonlight@inbox.com</Link></li>
					</ul>
				</div>
				<div>
					<p>Navigation</p>
					<hr />
					<ul>
						<li className={ 'navLink' }><Link href='/'>Home</Link></li>
						<li className={ 'navLink' }><Link href='/services'>Services</Link></li>
						<li className={ 'navLink' }><Link href='/about'>About Us</Link></li>
						<li className={ 'navLink' }><Link href='/gallery'>Gallery</Link></li>
						<li className={ 'navLink' }><Link href='/contact'>Contact Us</Link></li>
					</ul>
				</div>
				<div>
					<p>Services</p>
					<hr />
					<ul>
						<li>Artisan Neon Craftsmanship</li>
						<li>Custom Channel Letter Fabrication</li>
						<li>Precision Sign Box Production</li>
						<li>Pylon Sign Engineering</li>
						<li>High-Resolution Large Format Printing</li>
						<li>Expert Vinyl Graphics Application</li>
						<li>B2B Signage Manufacturing Servicess</li>
						<li>Cutting-Edge Programmable LED Displays</li>
						<li>Advanced Digital Sign Solutions</li>
						<li>Dynamic Video Display Systems</li>
						<li>Interactive Touchscreen Kiosk Development</li>
						<li>Professional Website Design Services</li>
					</ul>
				</div>
			</section>
			<section className={ styles.copyright }>
				<div>Vancouver Sign Expert - All Rights Reserved</div>
			</section>
		</footer>
	)
}