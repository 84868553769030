import 'styles/globals.scss';
import Layout from 'components/Layout';
import ErrorPage from 'next/error'

export default function MyApp({ Component, pageProps, status }) {

  return (
    	<>
			{ !status ? (
				<>
					<Layout>
						<Component {...pageProps} />
					</Layout>
				</>
			) : (
				<ErrorPage statusCode={404} />
			) } 
    	</>
  	)
}
